<template>
	<section id="not-found">
		<div class="glitch-container">
			<div class="glitch g-one">404</div>
			<div class="glitch g-two">404</div>
			<div class="glitch g-three">404</div>
		</div>
		<div class="no-exist">The page you are looking for does not exist.</div>
		<router-link to="/" class="go-home">Go Home</router-link>
	</section>
</template>

<style lang="scss" scoped>
#not-found {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: white;
	background-image: url(../assets/question_mark.jpg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center;
	font: {
		family: 'Raleway', sans-serif;
		weight: 700;
		style: italic;
	}
}
.no-exist{
	font-size: 2rem;
}
.glitch-container {
	position: relative;
}
.glitch {
	font: {
		size: 8.125em;
	}
	text-decoration: none;
	text-transform: uppercase;
	position: absolute;
	letter-spacing: 5px;
	transform: translate(-50%, -90%);
}
.g-one {
	color: #feb916;
	z-index: 0;
	left: 10px;
}
.g-two {
	color: white;
	z-index: 0;
	left: -10px;
}
.g-three {
	color: #0fc8ff;
	z-index: 2;
}

.go-home {
	margin-top: 1.7rem;
}
</style>